import TIM from 'tim-js-sdk';
import COS from "cos-js-sdk-v5";
import { openTipDialog } from '../page/dialogs/TipDialog.vue';

import { tryTimes } from './trytimes'

export function imclient(SDKAppID, userId, vm) {
      let options = {
            SDKAppID: SDKAppID
      };
      let tim = TIM.create(options);
      tim.setLogLevel(0);
      tim.registerPlugin({'cos-js-sdk': COS});
      let logined = false;
      let sdkReady = false;
      let receivedListener;

      const replyCallbacks = {};
      const cmdListeners = {};

      tim.on(TIM.EVENT.MESSAGE_RECEIVED, function(event) {
            const msgs = event.data;
            for (let msg of msgs) {
                  switch (msg.conversationType) {
                        case TIM.TYPES.CONV_GROUP: {
                              switch (msg.type) {
                                    case TIM.TYPES.MSG_TEXT: {
                                          const msgObj =  JSON.parse(msg.payload.text);
                                          if (typeof (replyCallbacks[msgObj.from]) === 'function') {
                                                replyCallbacks[msgObj.from](msgObj);
                                                delete replyCallbacks[msgObj.from];
                                          }
                                          if (typeof cmdListeners[msgObj.cmd] === 'function') {
                                                cmdListeners[msgObj.cmd](msgObj);
                                          }
                                          break;
                                    }
                              }
                              break;
                        }
                        case TIM.TYPES.CONV_C2C: {
                              switch (msg.type) {
                                    case TIM.TYPES.MSG_TEXT: {
                                          const msgObj =  JSON.parse(msg.payload.text);
                                          receivedListener && receivedListener(msgObj);
                                          break;
                                    }
                              }
                              break;
                        }
                  }
            }
      });

      let loginReadyResolve = null;
      let rejoinGroup = null;

      let __kickoutHandler

      tim.on(TIM.EVENT.SDK_READY, () => {
            sdkReady = true;
            if (logined && sdkReady) {
                  loginReadyResolve();
                  loginReadyResolve = null;
            }
      });
      let delayLoginTimer;
      tim.on(TIM.EVENT.SDK_NOT_READY, async () => {
            sdkReady = false;
            clearTimeout(delayLoginTimer);
            delayLoginTimer = setTimeout(async () => {
                  await reloginIfNeed();
            }, 1000);
      });

      tim.on(TIM.EVENT.KICKED_OUT, async (event) => {
            console.log(event);
            clearTimeout(delayLoginTimer);
            __kickoutHandler && __kickoutHandler();
            let msg = '当前账号正在其他设备直播，当前直播已中断';
            switch (event.data.type) {
                  case TIM.TYPES.KICKED_OUT_MULT_ACCOUNT:
                        msg = ('当前账号正在其他设备直播，当前直播已中断');
                        break;
                  case TIM.TYPES.KICKED_OUT_MULT_DEVICE:
                        msg = ('当前账号正在其他设备直播，当前直播已中断');
                        break;
                  case TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED:
                        msg = ('登录过期, 重新进入房间');
                        break;
                  default:
                        msg = ('当前账号正在其他设备直播，当前直播已中断');
                        break;
            }

            vm._cameraClient.hangUp();
            vm.isLianmaiFlag = false;
            vm.offing = false;
            vm.currentStudent = undefined;
            localStorage.removeItem('currentStudentCallId');

            try {
                  await vm._cameraClient.leaveRoom();
            } catch (error) {
                  console.error('被踢离开房间', error);
            }

            openTipDialog({
                  title: '温馨提示',
                  content: msg,
                  okbutton: '确定退出'
            }, vm).afterClosed().then(() => {
                  localStorage.setItem('kickout', 'true');
                  history.back();
            });
      });

      let reloginIfNeed;

      return {
            loginAndReady(userID, userSig) {
                  reloginIfNeed = async () => {
                        if (!sdkReady) {
                              try {
                                    await this.loginAndReady(userID, userSig)
                                    console.log('----------------再次登录---------------')
                              } catch (error) {
                                    console.error('----------------再次登录---------------', error);
                              }
                              try {
                                    await rejoinGroup();
                                    console.log('-----------------re join group, 再次入群-------------')
                              } catch(error) {
                                    console.error('-----------------re join group, 再次入群-------------', error);
                              }
                        }
                  };

                  return new Promise((resolve, reject) => {
                        loginReadyResolve = resolve;
                        tryTimes((retry) => {
                              tim.login( {userID, userSig} ).then(() => {
                                    logined = true;
                                    if (logined && sdkReady) {
                                          resolve();
                                    }
                              }).catch((error) => {
                                    if (!retry()) {
                                          reject(error)
                                    }
                              });
                        });
                  });
            },

            onListenerCmd(cmd, listener) {
                  cmdListeners[cmd] = listener;
            },

            logout() {
                  return new Promise((resolve, reject) => {
                        tim.logout().then(() => {
                              logined = false;
                              resolve();
                        }).catch((error) => {
                              console.error(error);
                              reject(error);
                        });
                  });
            },

            async joinGroup(groupID) {
                  await reloginIfNeed();
                  try {
                        await tim.searchGroupByID(`${groupID}`);
                  } catch (imError) {
                        await tim.createGroup({
                              type: TIM.TYPES.GRP_AVCHATROOM,
                              groupID: `${groupID}`,
                              name: `${groupID}`,
                              joinOption: TIM.TYPES.JOIN_OPTIONS_FREE_ACCESS
                        });
                  }

                  rejoinGroup = async () => {
                        const imResponse = await tim.joinGroup({ groupID, type: TIM.TYPES.GRP_AVCHATROOM });
                        if (imResponse.data.status === TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL) {
                              throw Error('加群失败: 需要管理员同意');
                        }
                  }

                  await rejoinGroup();

                  return {
                        async sendMessageForGroup(cmdObject) {
                              await reloginIfNeed();
                              cmdObject = {...cmdObject, from: userId}
                              return tim.sendMessage(tim.createTextMessage({
                                    to: `${groupID}`,
                                    conversationType: TIM.TYPES.CONV_GROUP,
                                    priority: TIM.TYPES.MSG_PRIORITY_HIGH,
                                    payload: {
                                          text: JSON.stringify(cmdObject)
                                    }
                              }), {
                                    onlineUserOnly: true
                              });
                        },

                        async postMessageForGroup(cmdObject) {
                              await reloginIfNeed();
                              cmdObject = {...cmdObject, from: userId}
                              return new Promise((resolve, reject) => {

                                    tim.sendMessage(tim.createTextMessage({
                                          to: `${groupID}`,
                                          conversationType: TIM.TYPES.CONV_GROUP,
                                          priority: TIM.TYPES.MSG_PRIORITY_HIGH,
                                          payload: {
                                                text: JSON.stringify(cmdObject)
                                          }
                                    }), {
                                          onlineUserOnly: true
                                    }).then(() => {
                                          replyCallbacks[cmdObject.to] = (replyCmdObj) => {
                                                clearTimeout(timerId);
                                                resolve(replyCmdObj)
                                          }
                                          const timerId = setTimeout(() => {
                                                delete replyCallbacks[cmdObject.to];
                                                reject(new Error('已经超时'));
                                          }, 5000);
                                    }).catch((error) => {
                                          reject(error);
                                    });
                              });
                        },
                        async quitGroup() {
                              return tim.quitGroup(groupID)
                        }
                  };
            },

            async call(toUserId, text) {
                  await reloginIfNeed();
                  return new Promise((resolve, reject) => {
                        tim.sendMessage(tim.createTextMessage({
                              to: toUserId,
                              conversationType: TIM.TYPES.CONV_C2C,
                              payload: {
                                    text
                              }
                        })).then((imResponse) => {
                              resolve(imResponse)
                        }).catch((error) => {
                              reject(error)
                        });
                  });
            },

            onWaiting(listener) {
                  receivedListener = listener;
            },

            onKickout(kickoutHandler) {
                  __kickoutHandler = kickoutHandler
            }
      }
}